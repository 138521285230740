import _ from 'underscore'
import ApplicationController from 'controllers/application_controller'
import datePickerController from 'vendor/datepicker.js.erb'
import HumanizedDate from 'lib/humanized_date'
import RealtimeWidgetController from 'controllers/realtime_widget_controller'

export default class extends ApplicationController {

  static targets = [
    'input',
    'dateSelectTrigger',
    'dateSelectDays'
  ]

  static values  = {
    availableFrom:          { type: String, default: null             },
    availableTill:          { type: String, default: null             },
    firstDay:               { type: Date,   default: null             },
    selectLabel:            { type: String, default: 'Choose a date'  },
    todayLabel:             { type: String, default: 'Today'          },
    currentWeekLabelFormat: { type: String, default: 'l'              },
    currentYearLabelFormat: { type: String, default: 'l-cc-sp-j-sp-F' },
    otherYearLabelFormat:   { type: String, default: 'j-sp-F-cc-sp-Y' }
  }

  static outlets = ['realtime-widget']

  /**
   * @returns {RealtimeWidgetController}
   */
  get widget() {
    return this.realtimeWidgetOutlet
  }

  get defaultDatePickerOptions() {
    let yesterday = new Date(new Date().setDate(new Date().getDate() - 1))

    return {
      staticPos:          true,
      fillGrid:           true,
      showWeeks:          false,
      constrainSelection: false,
      statusFormat:       'l-cc-sp-d-sp-F-sp-Y',
      rangeLow:           this.toDatePickerDate(yesterday),
      disabledDays:       this.widget.booking.disabledDays,
      formElements:       {
        [ this.inputTargets[0].id ]: '%Y',
        [ this.inputTargets[1].id ]: '%n',
        [ this.inputTargets[2].id ]: '%j'
      },
      callbackFunctions:  {
        dateset:      [this.datePickerDateSet.bind(this)],
        datereturned: [this.datePickerDateReturned.bind(this)],
        redraw:       [this.datePickerRedraw.bind(this)]
      }
    }
  }

  get randomId() {
    return `${this.identifier}-${parseInt(Math.random() * 1000000)}`
  }

  get datePickerContainerID() {
    if (this.element.id) {
      return `datepicker-container-${this.element.id}`
    } else {
      this.element.id = this.randomId
      return this.datePickerContainerID
    }
  }

  get datePickerContainer() {
    return document.getElementById(this.datePickerContainerID)
  }

  get dateSelectContainerID() {
    if (this.element.id) {
      return `dateselect-container-${this.element.id}`
    } else {
      this.element.id = this.randomId
      return this.dateSelectContainerID
    }
  }

  get dateSelectContainer() {
    return document.getElementById(this.dateSelectContainerID)
  }

  get value() {
    let values = _.map(this.inputTargets, (input) => input.value)

    if(values.indexOf('') < 0) {
      let dateParts = _.map(
        this.inputTargets, (input) => {
          let value = parseInt(input.value)
          if (value < 10) {
            return `0${value}`
          } else {
            return `${value}`
          }
        }
      )

      return new Date(dateParts.join('-'))
    }
  }

  set value(newValue) {
    _.defer(() => {
      this.selectDate(newValue)
      this.hideDatePicker()
      this.widget.refresh()
    })
  }

  get selectLabel() {
    if (this.value) {
      return this.humanizeDate(this.value)
    } else {
      return this.selectLabelValue
    }
  }

  get humanLabel() {
    if (this.value) {
      return this.humanizeDate(this.value, {
        todayLabel:        null,
        todayFormat:       this.currentYearLabelFormatValue,
        currentWeekFormat: this.currentYearLabelFormatValue
      })
    } else {
      return this.selectLabel
    }
  }

  // EVENTS:

  availableFromValueChanged (newValue, oldValue) {
    if (this.isDifferentObject(newValue, oldValue)) {
      if (newValue && newValue.length) {
        newValue = newValue.replace(/-/g, '')
      } else {
        newValue = this.toDatePickerDate(
          new Date(new Date().setDate(new Date().getDate() - 1)) // Yesterday
        )
      }

      datePickerController.setRangeLow(this.datePickerID, newValue)
    }
  }

  availableTillValueChanged (newValue, oldValue) {
    if (this.isDifferentObject(newValue, oldValue)) {
      if (newValue && newValue.length) {
        newValue = newValue.replace(/-/g, '')
      } else {
        newValue = this.toDatePickerDate(
          new Date(new Date().setFullYear(new Date().getFullYear() + 5)) // 5 Years from now
        )
      }

      datePickerController.setRangeHigh(this.datePickerID, newValue)
    }
  }

  connect() {
    _.defer(() => {
      this.initializeDateSelect()
      this.initializeDatePicker()
    })
  }

  initializeDateSelect() {
    if (!this.dateSelectContainer) {
      let container = document.createElement('section')
      container.id = this.dateSelectContainerID
      container.className = 'date-select date-select-enabled'
      this.element.appendChild(container)

      this.morph(container, `
        <a class="date-select-trigger" data-${this.identifier}-target="dateSelectTrigger" data-action="click->${this.identifier}#toggleDatePicker">${this.selectLabel}</a>
        <fieldset class="date-select-days" data-${this.identifier}-target="dateSelectDays">
        </fieldset>
      `)

      this.refreshDateSelectDays()
    }
  }

  initializeDatePicker() {
    if (!this.datePickerContainer) {
      datePickerController.setDebug(true)

      let datePickerOptions = this.defaultDatePickerOptions

      let container = document.createElement('div')
      container.className = 'date-picker-wrapper'
      container.id = datePickerOptions.positioned = this.datePickerContainerID

      if (this.dateSelectContainer) {
        this.dateSelectContainer.appendChild(container)
      } else {
        this.element.appendChild(container)
      }

      datePickerController.createDatePicker(datePickerOptions)
      this.datePickerID = datePickerOptions.id

      this.refreshDates()

      this.element.classList.add('datepicker-enabled')
    }
  }

  isDisabledDate(date) {
    return this.widget.booking.isDisabledDate(date)
  }

  refreshDates() {
    let booking = this.widget.booking

    let disabledDays  = booking.disabledDays
    let disabledDates = [...booking.closedDatesValue]
    let enabledDates  = booking.openDatesValue

    let firstDate = this.firstDate || new Date(new Date().setDate(new Date().getDate() -  1))
    let lastDate  = this.lastDate  || new Date(new Date().setDate(new Date().getDate() + 31))

    let date = firstDate
    while (date <= lastDate) {
      if (this.isDisabledDate(date)) {
        disabledDates.push([this.toDatePickerDate(date)])
      }

      date.setDate(date.getDate() + 1)
    }

    this.setDisabledDays(disabledDays)
    this.setDisabledDateRanges(disabledDates)
    this.setEnabledDateRanges(enabledDates)

    this.redrawDatePicker()
  }

  redrawDatePicker() {
    datePickerController.updateTable(this.datePickerID)
  }

  setDisabledDateRanges(dateRanges) {
    dateRanges = this.toDatePickerRange(dateRanges)

    datePickerController.startUpdate(this.datePickerID)
    datePickerController.setDisabledDates(this.datePickerID, dateRanges)
    datePickerController.stopUpdate(this.datePickerID)
  }

  setEnabledDateRanges(dateRanges) {
    dateRanges = this.toDatePickerRange(dateRanges)

    datePickerController.startUpdate(this.datePickerID)
    datePickerController.setEnabledDates(this.datePickerID, dateRanges)
    datePickerController.stopUpdate(this.datePickerID)
  }

  setDisabledDays(disabledDays) {
    datePickerController.startUpdate(this.datePickerID)
    datePickerController.setDisabledDays(this.datePickerID, disabledDays)
    datePickerController.stopUpdate(this.datePickerID)
  }

  refreshDateSelectDays() {
    if (this.hasDateSelectDaysTarget && this.widget.booking.upcomingDaysValue.length > 0) {
      let listItems = this.widget.booking.upcomingDaysValue.map(
        (value, index) => {
          return `
            <li class="date-select-day"
                data-${this.identifier}-date-param="${value}"
                data-action="click->${this.identifier}#selectDay">
              <span>${this.humanizeDate(new Date(value))}</span>
              ${index === 0 ? '<div class="date-select-triangle"></div>' : ''}
            </li>
          `
        }
      )

      this.morph(this.dateSelectDaysTarget, `<ul>${listItems.join('')}</ul>`)
    }
  }

  toggleDatePicker(event) {
    if (this.dateSelectContainer.classList.contains('is-active')) {
      this.hideDatePicker(event)
    } else {
      this.showDatePicker(event)
    }
  }

  hideDatePicker(event) {
    if (this.dateSelectContainer.classList.contains('is-active')) {
      this.dateSelectContainer.classList.remove('is-active')
      document.querySelector('.reservations-widget-step-one').classList.remove('date-select-open')

      if (this.embedded) {
        this.embedded.resetTitle()
        this.embedded.reportWidgetSize()
      }
    }
  }

  showDatePicker(event) {
    if (!this.dateSelectContainer.classList.contains('is-active')) {
      this.dateSelectContainer.classList.add('is-active')
      document.querySelector('.reservations-widget-step-one').classList.add('date-select-open')

      if (this.embedded) {
        this.embedded.title = this.selectLabelValue
        this.embedded.reportWidgetSize()
      }
    }
  }

  selectDate(date) {
    if (date) {
      let selectedDate = date.replace(/\-/g, '')
      datePickerController.setSelectedDate(this.inputTargets[0].id, selectedDate)
    } else {
      datePickerController.unselectDate(this.inputTargets[0].id)
      this.inputTargets.forEach((input) => input.value = null)
      this.updateSelectedDate()
    }
  }

  selectDay(event) {
    this.selectDate(event.params.date)
  }

  updateSelectedDate() {
    if (this.hasDateSelectTriggerTarget) {
      this.morph(this.dateSelectTriggerTarget, this.selectLabel)
    }
  }

  // DATE PICKER EVENTS:

  datePickerRedraw(event) {
    let firstDate = this.firstDate = this.extractDatePickerDate(event.firstDateDisplayed)
    let lastDate  = this.lastDate  = this.extractDatePickerDate(event.lastDateDisplayed)
    let booking   = this.widget.booking

    if (booking) {
      _.defer(() => { booking.fetchRangedAvailability(firstDate, lastDate) })
    } else {
      _.defer(() => { this.refreshDates() })
    }

    this.redrawDatePicker()

    if (this.embedded) {
      this.embedded.reportWidgetSize()
    }
  }

  datePickerDateSet(event) {
    this.updateSelectedDate()
    _.defer(() => { this.changed(event) })
  }

  datePickerDateReturned(event) {
    this.toggleDatePicker(event)
    this.datePickerDateSet(event)
  }

  // EVENTS:

  changed(event) {
    this.widget.refreshFromDate()
  }

  // UTILITY:

  humanizeDate(date, options = {}) {
    options = {
      todayLabel:        this.todayLabelValue,
      currentWeekFormat: this.currentWeekLabelFormatValue,
      currentYearFormat: this.currentYearLabelFormatValue,
      otherYearFormat:   this.otherYearLabelFormatValue,
      ...options
    }

    return (new HumanizedDate(date, options)).humanizedDate()
  }

  toDatePickerRange(dateRanges) {
    let formattedDateRanges = {}

    dateRanges.forEach((range) => {
      let values

      if (!Array.isArray(range)) {
        values = [range, range]
      } else {
        values = Array.from(range.values())
      }

      let startDate = this.toDatePickerDate(this.extractDatePickerDate(values[0]))
      let endDate   = this.toDatePickerDate(this.extractDatePickerDate(values[1] || values[0]))

      formattedDateRanges[startDate] = endDate
    })

    return formattedDateRanges
  }

  toDatePickerDate(date) {
    try {
      if (date && date.toISOString) {
        return date.toISOString().slice(0,10).replace(/-/g,"")
      } else {
        console.log('toDatePickerDate invalid date', date)
      }
    } catch (error) {
      console.log('toDatePickerDate error', error, {
        arguments: [date],
        firstDate: this.firstDate,
        lastDate:  this.lastDate
      })
    }
  }

  extractDatePickerDate(date) {
    if (date && date.getDate) {
      return date
    } else if (date) {
      let parts = date.replace(/-/g,"").match(/^([0-9]{4})([0-9]{2})([0-9]{2})$/)

      let year  = parseInt(parts[1])
      let month = parseInt(parts[2]) - 1 // Javascript months start at 0
      let day   = parseInt(parts[3])

      let newDate = new Date(0)
      newDate.setUTCFullYear(year)
      newDate.setUTCMonth(month)
      newDate.setUTCDate(day)
      newDate.setUTCHours(12)

      return newDate
    }
  }
}
